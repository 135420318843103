.App {
    width: 902px;
    margin: auto;
    margin-bottom: 10em;
}

.App h1 {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 500;
    margin: 1.5em auto 0.25em;
    letter-spacing: normal;
}

.App h2 {
    margin-bottom: 1em;
    font-weight: 200;
    font-size: 26px;
}

.App h3 {
    font-size: 21px;
    margin-top: 1.42857em;
    line-height: normal;
    letter-spacing: normal;
}

.App p,
.App li {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 200;
    letter-spacing: normal;
    margin-top: 1em;
}

@media screen and (max-width: 1080px) {
    html {
        font-size: calc(100vw / 10);
    }

    .App {
        width: 8.9333rem;
    }

    .App h1 {
        margin: 1.5em auto 0.25em;
        font-size: 0.48rem;
    }

    .App h2 {
        font-size: 0.4533rem;
        margin-bottom: 1em;
    }

    .App h3 {
        font-size: 0.4267rem;
    }

    .App p,
    .App li {
        font-size: 0.4rem;
        margin-top: 1em;
    }

    .App ul {
        font-size: 0.5em;
    }
}